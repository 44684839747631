<template>
  <span>
    <span v-b-modal="'vni-vlans-' + bcd.name" class="highlight-link">
              <template v-if="bcd_vni">
                  {{ format_vni(bcd_vni.vni) }}
              </template>
                <i v-else>{{ $t('components.bcd_list.no_vni_set') }}</i>
    </span>
    <b-modal
        v-if="bcd_vni || bcd_vlans.length > 0"
        ok-only
        :title="bcd_vni ? ('VNI ' + format_vni(bcd_vni.vni)) : $t('components.bcd_list.no_vni_set')"
        :id="'vni-vlans-' + bcd.name">
      <p>{{$t('system.net_cmpnd_explain')}}</p>
      <b-table :fields="vlan_fields"
               :items="bcd_vlans">
        <template v-slot:cell(net_cmpnd)="data">
          <ul style="padding-left: 1em;">
            <li v-bind:key="cmpnd.net_cmpnd" v-for="cmpnd in netcmpnds_by_net_instnc[data.item.net_instnc]">
              {{cmpnd.net_cmpnd}}: {{$t_netdb(netcmpnds_by_name[cmpnd.net_cmpnd].description)}}
            </li>
          </ul>
        </template>
      </b-table>
    </b-modal>
  </span>
</template>
<script>
import Vniutil from '@/util/vniutil'

export default {
  name: 'BCDId',
  methods: {
    format_vni: Vniutil.format_vni
  },
  data() {
    return {
      vlan_fields: [
        {
          label: this.$t('system.vlan_id'),
          key: 'id'
        },
        {
          label: this.$t('system.netinst_nc'),
          key: 'net_instnc'
        },
        {
          label: this.$tc('system.net_cmpnd', 2),
          key: 'net_cmpnd'
        },
      ]
    }
  },
  props: {
    bcd: {
      type: Object,
      required: true
    },
    bcd_vni: {
      type: Object,
      required: false,
      default() {
        return null
      }
    },
    bcd_vlans: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    netcmpnds_by_net_instnc: {
      type: Object,
      required: true,
    },
    netcmpnds_by_name: {
      type: Object,
      required: true,
    },
  }
}
</script>

<style>
.highlight-link {
  text-decoration-style: dashed;
  text-decoration-line: underline;
  cursor: help;
}
</style>
