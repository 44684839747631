<template>
  <div class="bcd_overview">
    <Loading :data="[bcds, ous, bcd2ous_by_bcd]">
      <FilterInput v-model="filter"></FilterInput>
      <b-pagination v-if="bcds && bcds.length > per_page && filter === ''" :total-rows="bcds.length"
                    :per-page="per_page" v-model="current_page" align="center"></b-pagination>
      <b-card no-body class="shadow">
        <b-table responsive :filter="filter" :per-page="filter === '' ? per_page : null"
                 :current-page="filter === '' ? current_page : null" :fields="bcd_list_fields" :busy="bcds === null"
                 :items="bcds"
                 :sort-compare="sortBCDOverview" :sort-by="sort_by">
          <template v-slot:cell(name)="data">
            <b-link class="nowrap" :to="'bcds/'+data.value"><b>{{ data.value }}</b></b-link>
          </template>
          <template v-slot:cell(vni)="data">
            <BCDId :netcmpnds_by_net_instnc="netcmpnds_by_net_instnc" :netcmpnds_by_name="netcmpnds_by_name" :bcd_vlans="bcd2vlans[data.item.name]" :bcd="data.item" :bcd_vni="bcd2vnis[data.item.name]"/>
          </template>
          <template v-slot:cell(subnets)="data">
            <div v-for="subnet in bcd2subnets[data.item.name]?.slice().sort((a, b) => -a.type.localeCompare(b.type))"
                 :key="subnet.cidr" class="table-sub-row">
              <p><code class="nowrap">{{ subnet.cidr }}</code></p>
            </div>
          </template>
          <template v-slot:cell(usage)="data">
            <div v-for="subnet in bcd2subnets[data.item.name]?.slice().sort((a, b) => -a.type.localeCompare(b.type))"
                 :key="subnet.cidr" class="table-sub-row">
              <b-progress class="usage-bar"
                          v-if="isIPv4(subnet.cidr)"
                          :max="IPAddressCount(subnet.cidr)">
                <b-progress-bar
                  :variant="progressVariant(usagePercentage(subnet))"
                  :value="subnet.dns_addr_rr_count"/>
                <div class="better-progress-label">{{ usagePercentage(subnet) + '%' }}</div>
              </b-progress>
              <p v-else class="usage-na"><i>{{ $t('system.n/a') }}</i></p>
            </div>
          </template>
          <template v-slot:cell(adresses)="data">
            <div v-for="subnet in bcd2subnets[data.item.name]?.slice().sort((a, b) => -a.type.localeCompare(b.type))"
                 :key="subnet.cidr" class="table-sub-row">
              <p>{{ subnet.dns_addr_rr_count }}</p>
            </div>
          </template>
          <template v-slot:cell(ou_short_name)="data">
            <ul>
              <li :key="ou.ou_short_name" class="nowrap" v-for="ou in bcd2ous_by_bcd[data.item.name]">
                <b-link :to="'/org/ou/' + ou.ou_short_name">
                  <span
                    :title="ous_by_short_name[ou.ou_short_name].name" class="nowrap">{{ ou.ou_short_name }}</span>
                </b-link>
              </li>
            </ul>
          </template>
          <template v-slot:cell(vlans)="data">
            <ul v-if="data.item.name in bcd2vlans">
              <li v-for="vlan in bcd2vlans[data.item.name]"
                  :key="vlan.id" class="nowrap" :title="vlan.net_instnc">
                {{ vlan.name }} ({{ vlan.id }})
              </li>
            </ul>
            <i v-else>{{ $t('components.bcd_list.no_vlans_available') }}</i>
          </template>
        </b-table>
        <div v-if="bcds && bcds.length === 0" class="font-italic text-center mb-3">
          {{ $t('components.bcd_list.no_bcds_available') }}
        </div>
        <b-pagination v-if="bcds && bcds.length > per_page && filter === ''" :total-rows="bcds.length"
                      :per-page="per_page" v-model="current_page" align="center"></b-pagination>
      </b-card>
    </Loading>
  </div>
</template>

<script>
import ApiUtil from '@/util/apiutil'
import VNIUtil from '@/util/vniutil'
import ipaddress from '@/util/ipaddress'
import Loading from '@/components/Loading'
import FilterInput from '@/components/FilterInput'
import BCDId from '@/components/BCDId.vue'

export default {
  name: 'BCDList',
  components: { BCDId, FilterInput, Loading },
  watch: {
    filter: {
      immediate: true,
      async handler() {
        if (this.filter_callback !== null) {
          const result = await this.filter_callback(this.filter)
          if (result !== null) {
            this.filter = result
          }
        }
      }
    },
    $props: {
      immediate: true,
      deep: true,
      async handler() {
        if (this.ous !== null) {
          this.ous_by_short_name = ApiUtil.dict_by_value_of_array(this.ous, 'short_name')
        }
        if (this.bcd2ous !== null) {
          this.bcd2ous_by_bcd = ApiUtil.dict_of_lists_by_value_of_array(this.bcd2ous, 'bcd_name')
        }
        this.sort_by = this.default_sort_by
      }
    }
  },
  data() {
    return {
      ous_by_short_name: {},
      filter: '',
      current_page: 1,
      per_page: 100,
      sort_by: null,
      bcd2ous_by_bcd: null,
      vlan_fields: [
        {
          label: this.$t('system.vlan_id'),
          key: 'id'
        },
        {
          label: this.$t('system.netinst_nc'),
          key: 'net_instnc'
        },
      ]
    }
  },
  computed: {
    bcd_list_fields() {
      let result = [
        {
          label: 'ID',
          key: 'vni',
          sortable: false
        },
        {
          label: this.$t('system.name'),
          key: 'name',
          sortable: true
        },
        {
          label: this.$t('system.description'),
          key: 'description',
          sortable: true
        },
        {
          label: this.$tc('system.subnet', 2),
          key: 'subnets',
          sortable: false
        },
        {
          label: this.$t('system.OE'),
          key: 'ou_short_name',
          sortable: false
        },
      ]
      if (this.show_usage) {
        result = result.concat([
          {
            label: this.$t('system.usage'),
            key: 'usage',
            sortable: true
          },
          {
            label: this.$t('system.assigned'),
            key: 'adresses',
            sortable: true
          }
        ])
      }
      result = result.concat([
        {
          label: this.$t('system.OE'),
          key: 'ou_short_name',
          sortable: false
        }
      ])
      return result
    }
  },
  props: {
    default_sort_by: {
      required: false,
      default() {
        return null
      }
    },
    bcd2vlans: {
      required: true,
      default() {
        return null
      }
    },
    bcd2vnis: {
      required: true,
      default() {
        return null
      }
    },
    bcd2subnets: {
      required: true,
      default() {
        return null
      }
    },
    bcd2ous: {
      required: true,
      default() {
        return null
      }
    },
    bcds: {
      required: true,
      default() {
        return null
      }
    },
    ous: {
      required: true,
      default() {
        return null
      }
    },
    show_usage: {
      required: false,
      default() {
        return true
      }
    },
    filter_callback: {
      required: false,
      default() {
        return null
      }
    },
    netcmpnds_by_net_instnc: {
      type: Object,
      required: true,
    },
    netcmpnds_by_name: {
      type: Object,
      required: true,
    },
  },
  methods: {
    format_vni: VNIUtil.format_vni,
    isIPv4: ipaddress.is_ip_v4,
    IPAddressCount: (net) => ipaddress.ip_num_addr(net),
    usagePercentage(subnet) {
      return (subnet.dns_addr_rr_count / this.IPAddressCount(subnet.cidr) * 100).toFixed(0)
    },
    progressVariant(percentage) {
      if (percentage <= 50) {
        return 'success'
      } else if (percentage <= 75) {
        return 'warning'
      } else {
        return 'danger'
      }
    },
    sortBCDOverview(a, b, key) {
      if (key === 'usage' || key === 'adresses') {
        if (!(a.name in this.bcd2subnets) && b.name in this.bcd2subnets) {
          return -1
        }
        if ((a.name in this.bcd2subnets) && !(b.name in this.bcd2subnets)) {
          return 1
        }
        if (!(a.name in this.bcd2subnets) && !(b.name in this.bcd2subnets)) {
          return 0
        }
      }
      if (key === 'usage') {
        let usage_a = 0
        let usage_b = 0
        this.bcd2subnets[a.name].forEach((subnet) => {
          if (this.isIPv4(subnet.cidr)) {
            usage_a += this.usagePercentage(subnet)
          }
        })
        this.bcd2subnets[b.name].forEach((subnet) => {
          if (this.isIPv4(subnet.cidr)) {
            usage_b += this.usagePercentage(subnet)
          }
        })
        return usage_a - usage_b
      } else if (key === 'adresses') {
        let adresses_a = 0
        let adresses_b = 0
        this.bcd2subnets[a.name].forEach((subnet) => {
          adresses_a += subnet.dns_addr_rr_count
        })
        this.bcd2subnets[b.name].forEach((subnet) => {
          adresses_b += subnet.dns_addr_rr_count
        })
        return adresses_a - adresses_b
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.better-progress-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 1.5rem;
}

.table-sub-row {
  height: 30px;
}

.usage-bar,
.usage-na {
  position: relative;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
}

.usage-bar {
  min-width: 120px;
}

</style>
