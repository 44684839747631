import TransactionService from './transaction.service'

export default {
  getOverview(config) {
    const ta = [
      { name: 'nd.bcd.list', old: { is_own: true, sorting_params_list: ['name'] } },
      { name: 'nd.bcd2ou.list', inner_join_ref: { bcd_list: 'default' } },
      { name: 'org.unit.list', inner_join_ref: { bcd2ou_list: 'default' } },
      { name: 'nd.ip_subnet.list', inner_join_ref: { bcd_list: 'default' } },
      { name: 'nd.vlan.list', inner_join_ref: { bcd_list: 'default' } },
      { name: 'nd.vxlan.list', inner_join_ref: { bcd_list: 'default' } },
      { name: 'ndcfg.bcd_categ.list', inner_join_ref: { bcd_list: 'default' } },
      { name: 'ndcfg.bcd_seclvl.list', inner_join_ref: { bcd_list: 'default' } },
      { name: 'ndcfg.net_instnc.list', inner_join_ref: { vlan_list: 'default' } },
      { name: 'ndcfg.net_cmpnd2instnc.list', inner_join_ref: { net_instnc_list: 'default' } },
      { name: 'ndcfg.net_cmpnd.list', inner_join_ref: { net_cmpnd2instnc_list: 'default' } },
    ]
    return TransactionService.execute(config, ta)
  },
  getOverviewV4Only(config) {
    const ta = [
      { name: 'nd.ip_subnet.list', old: { is_own: true, type: '4' } },
      { name: 'nd.bcd.list', inner_join_ref: { ip_subnet_list: 'default' } },
      { name: 'nd.bcd2ou.list', inner_join_ref: { bcd_list: 'default' } },
      { name: 'org.unit.list', inner_join_ref: { bcd2ou_list: 'default' } },
      { name: 'nd.vlan.list', inner_join_ref: { bcd_list: 'default' } }
    ]
    return TransactionService.execute(config, ta)
  },
  getDetail(config, name) {
    const ta = [
      { name: 'nd.bcd.list', old: { name_list: [name] } },
      { name: 'nd.bcd2ou.list', inner_join_ref: { bcd_list: 'default' } },
      { idx: 'ou_base', name: 'org.unit.list', inner_join_ref: { bcd2ou_list: 'default' } },
      { name: 'org.unit.list', inner_join_ref: { ou_base: 'api_func_org_unit_hierarchy_gfk_list_is_subset' } },
      {
        name: 'nd.ip_subnet.list',
        inner_join_ref: { bcd_list: 'default' },
        old: { sorting_params_list: ['type ASC', 'cidr ASC'] }
      },
      {
        name: 'dns.ip_addr.list',
        old: { is_reserved: true, sorting_params_list: ['value'] },
        inner_join_ref: { ip_subnet_list: 'default' }
      },
      { name: 'nd.bcd2group.list', inner_join_ref: { bcd_list: 'default' } },
      { idx: 'mgr_groups', name: 'cntl.group.list', inner_join_ref: { bcd2group_list: 'default' } },
      { name: 'cntl.mgr2group.list', inner_join_ref: { mgr_groups: 'default' } },
      { name: 'cntl.mgr.list', inner_join_ref: { mgr2group_list: 'default' } },
      { name: 'dns.fqdn2group.list', inner_join_ref: { mgr_groups: 'default' } },
      { name: 'cntl.mgr2ou.list', inner_join_ref: { unit_list: 'default' } },
      { idx: 'ou_mgrs', name: 'cntl.mgr.list', inner_join_ref: { mgr2ou_list: 'default' } },
      { name: 'nd.vlan.list', inner_join_ref: { bcd_list: 'default' } },
      { name: 'nd.vxlan.list', inner_join_ref: { bcd_list: 'default' } },
      { name: 'ndcfg.bcd_categ.list', inner_join_ref: { bcd_list: 'default' } },
      { name: 'ndcfg.bcd_seclvl.list', inner_join_ref: { bcd_list: 'default' } },
      { idx: 'nat_info', name: 'cntl.ot_attr_val.list', inner_join_ref: { ip_subnet_list: null }},
      { name: 'ndcfg.net_instnc.list', inner_join_ref: { vlan_list: 'default' } },
      { name: 'ndcfg.net_cmpnd2instnc.list', inner_join_ref: { net_instnc_list: 'default' } },
      { name: 'ndcfg.net_cmpnd.list', inner_join_ref: { net_cmpnd2instnc_list: 'default' } },
    ]
    return TransactionService.execute(config, ta)
  },
  getRecords(config, bcd) {
    const ta = [
      { name: 'nd.bcd.list', old: { name_list: [bcd] } },
      {
        name: 'nd.ip_subnet.list',
        inner_join_ref: { bcd_list: 'default' }
      },
      {
        name: 'dns.ip_addr.list',
        inner_join_ref: { ip_subnet_list: 'default' }
      },
      {
        name: 'dns.record.list',
        old: {
          sorting_params_list: ['type', 'target_ipaddr', 'data', 'fqdn'],
          is_auth: true
        },
        inner_join_ref: { ip_subnet_list: 'api_func_dns_record_rr_chain_target_contains_subnet' }
      },
      { name: 'dns.fqdn.list', inner_join_ref: { record_list: 'default' } },
      {
        idx: 'inttype_pre',
        name: 'dnscfg.record_inttype.list',
        old: {
          is_own: true,
          record_type_is_addr_based: false,
          record_type_is_name_based: false
        }
      },
      {
        name: 'dnscfg.record_inttype.list',
        old: {
          is_own: true,
          sorting_params_list: ['record_type']
        },
        anti_join_ref: { inttype_pre: 'self' }
      },
      { idx: 'record_types_pre', name: 'dnscfg.record_type.list', old: { is_addr_based: false, is_name_based: false } },
      { name: 'dnscfg.record_type.list', anti_join_ref: { record_types_pre: 'self' } },
      { name: 'dnscfg.fqdn_type.list', old: { sorting_params_list: ['position'] } }
    ]
    return TransactionService.execute(config, ta)
  }
}
